import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Layout from '../components/layout';
import PrimaryAppBar from '../components/appBar/PrimaryAppBar';

import ComingSoonTo from '../components/shared/comingSoonTo';
import BendableHeroBG1 from '../components/shared/bendableHeroBG1';
import Footer from '../components/footer/Footer';


const PomonaPage = (props) => {

  return (
    <Layout pageTitle="Bendable for Libraries">
      <PrimaryAppBar instanceName="Pomona" backPath="/" />

      {/* ROW 1 */}
      <Grid
        container
        direction={{ xs: 'column', lg: 'row'}}
        sx={{
          width: '100%',
          height: '100%',
          background: '#ffffff',
          // alignItems: 'center',
          marginTop: { xs: '57px' , lg: '94px' }, 
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
          sx={{ overflow: 'hidden' }}
        >
          <ComingSoonTo libraryName="Pomona" />
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <BendableHeroBG1 />
        </Grid>
      </Grid>

      <Footer />
    </Layout>
  )
}

export default PomonaPage;